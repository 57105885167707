




























































import { Component, Vue } from 'vue-property-decorator';
import { DataTableColumn, DataTableSearchable } from '@/layouts/back-office/elements/datatable/DataTableMixin';
import { Meta } from '@sophosoft/vue-meta-decorator';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import SendPasswordCreateMailAction from '@/components/account/actions/SendPasswordCreateMailAction.vue';

@Component({ components: { QueryDataTable, SendPasswordCreateMailAction } })
export default class DealerAccounts extends Vue {
  @Meta
  private getMetaInfo() {
    return {
      title: this.$it('menu.accounts.title', 'Accounts'),
    };
  }

  /**
   * The DataTable searchable
   */
  private get searchable(): DataTableSearchable[] {
    return [
      {
        key: 'name',
        name: this.$it('accounts.col.name.title', 'Name').toString(),
      },
      {
        key: 'email',
        name: this.$it('accounts.col.email.title', 'Email').toString(),
      },
    ];
  }

  /**
   * The DataTable columns
   */
  private get columns(): DataTableColumn[] {
    return [
      {
        title: this.$it('accounts.col.name.title', 'Name').toString(),
        key: 'name',
        sortable: true,
        class: 'data-table-col-middle',
        width: 250,
      },

      {
        title: this.$it('accounts.col.email.title', 'Email').toString(),
        key: 'email',
        sortable: true,
        class: 'data-table-col-middle',
        width: 250,
      },
      {
        title: this.$it('accounts.col.created.title', 'Created').toString(),
        key: 'hasPassword',
        sortable: true,
        class: 'data-table-col-center data-table-col-fixed',
        width: 100,
      },
      {
        title: this.$it('accounts.col.inspection-open.title', 'Insp. Open').toString(),
        key: 'inspectionsAmountTotal',
        class: 'data-table-col-center data-table-col-fixed',
        width: 120,
      },
      {
        title: this.$it('accounts.col.app-version.title', 'App version').toString(),
        key: 'device.appVersion',
        class: 'data-table-col-center data-table-col-fixed',
        slot: 'appVersion',
        width: 120,
      },
    ];
  }

  private getAppVersionMessage(item: any) {
    if (item.device === null) {
      return this.$it('accounts.device.error.no-app-installed', 'This user has no app installed');
    } else if (item.device.outdated) {
      return this.$it('accounts.device.error.app-outdated', 'The app of the user is outdated');
    } else {
      return this.$it('accounts.device.up-to-date', 'The app is up to date');
    }
  }
}
