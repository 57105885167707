var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{ref:"apolloQuery",attrs:{"client-id":_vm.clientId,"query":_vm.query,"fetch-policy":_vm.fetchPolicy,"variables":_vm.variablesQuery,"update":_vm.resolveQueryData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [(error)?[_c('div',{staticClass:"alert alert-danger"},[_c('strong',[_vm._v(_vm._s(_vm.$it('global.error', 'Something went wrong, please try again')))]),_c('p',[_vm._v(_vm._s(error))])])]:_c('data-table',{attrs:{"title":_vm.title,"data-table-id":_vm.identifier,"key-field":_vm.keyField,"loading":isLoading === 1,"data":data || [],"header":_vm.header,"bordered":_vm.bordered,"columns":_vm.columns,"filters":_vm.filters,"can-clear-filters":_vm.canClearFilters,"row-height":_vm.rowHeight,"searchable-fields":_vm.searchableFields,"default-sort":_vm.defaultSort,"default-sort-direction":_vm.defaultSortDirection,"pagination":_vm.pagination,"back":_vm.back,"offline-sort":!_vm.onlineSort,"buffer":_vm.buffer,"extend":_vm.extend},on:{"loadMore":_vm.loadMore},scopedSlots:_vm._u([(_vm.selectable)?{key:"actions",fn:function(ref){
var register = ref.register;
var selected = ref.selected;
return [_vm._t("actions",null,{"register":register,"selected":selected})]}}:null,{key:"append-title",fn:function(){return [_vm._t("append-title")]},proxy:true},{key:"data-table-prepend",fn:function(ref){
var pagination = ref.pagination;
return [_vm._t("data-table-prepend",null,{"pagination":pagination,"isLoading":isLoading})]}},{key:"action-bar-buttons",fn:function(){return [_vm._t("action-bar-buttons")]},proxy:true},_vm._l((_vm.columnSlots),function(slot){return {key:slot,fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_vm._t(slot,null,{"value":value,"column":column,"item":item})]}}}),{key:"data-table-append",fn:function(ref){
var pagination = ref.pagination;
return [_vm._t("data-table-append",null,{"pagination":pagination,"queryVariables":_vm.variablesQuery,"isLoading":isLoading})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }