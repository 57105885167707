


































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import * as Sentry from '@sentry/browser';
import DataTable from '@/layouts/back-office/elements/datatable/DataTable.vue';
import DataTableMixin from '@/layouts/back-office/elements/datatable/DataTableMixin';
import slugify from '@/filters/vue/slugify';
import {DataTablePagination} from '@/layouts/back-office/elements/datatable/components/DataTablePaginator.vue';
import {SmartQuery} from 'vue-apollo/types/vue-apollo';
import {StateChanger} from 'vue-infinite-loading';
import {PaginatorInfo} from '@/types/auction';

@Component({
  components: {DataTable},
})
export default class QueryDataTable extends Mixins(DataTableMixin) {
  @Prop([Function, Object]) protected query!: () => void | object;
  @Prop(String) protected clientId!: string;
  @Prop(String) protected dataKey!: string;
  @Prop({type: String, default: 'cache-first'}) protected fetchPolicy!: string;
  @Prop({type: Boolean, default: true}) protected infinite!: boolean;
  @Prop(Object) protected variables?: object;
  @Prop(Number) protected rowHeight?: number;
  @Prop(String) protected title!: string | null;
  @Prop(Function) protected resolveData?: (data: any) => any;
  @Prop({type: Boolean, default: true}) protected onlineSort!: boolean;
  @Prop({type: Boolean, default: true}) protected extend!: boolean;

  private stateChanger?: StateChanger;
  private pagination: DataTablePagination | null = null;
  private variablesQuery: any = {};

  private get queryResult(): SmartQuery<any> {
    return (this.$refs.apolloQuery as any).getApolloQuery();
  }

  public refresh() {
    if (this.queryResult) {
      this.queryResult.refetch();
    }
    if (this.stateChanger) {
      this.stateChanger.reset();
    }
  }

  protected created() {
    this.setQueryKeys(this.getIdentifier());

    this.updateVariables();
  }

  @Watch('query')
  @Watch('variablesQuery')
  private updateRefetchQuery(): void {
    this.$emit( 'refetchQuery', {
      query: this.query,
      variables: this.variablesQuery,
    });
  }

  @Watch('$route.query')
  @Watch('variables')
  private updateVariables(): void {
    this.variablesQuery = {
      ...Object.assign({}, this.variables || {}),
      ...this.queryVariables,
    };

    this.$emit('variableUpdated', this.variablesQuery);
  }

  private getIdentifier() {
    if (this.dataTableId.length > 0) {
      return this.dataTableId;
    }

    const identifier = Object.keys(this.query).filter((key) => ['kind', 'definitions', 'loc'].indexOf(key) < 0)[0];
    return slugify(identifier);
  }

  private resolveQueryData(data: any): object[] {
    this.$emit('update', data);

    if (data && this.dataKey in data) {
      data = data[this.dataKey];

      if ('paginatorInfo' in data) {
        const paginatorInfo: PaginatorInfo = data.paginatorInfo;

        this.pagination = {
          from: paginatorInfo.firstItem,
          to: paginatorInfo.lastItem,
          currentPage: paginatorInfo.currentPage,
          perPage: paginatorInfo.perPage,
          lastPage: paginatorInfo.lastPage,
          hasMorePages: paginatorInfo.hasMorePages,
          total: paginatorInfo.total,
          infiniteLoading: this.infinite,
        };

        data = data.data;
      } else if ('data' in data) {
        this.pagination = {
          from: data.from,
          to: data.to,
          currentPage: data.current_page,
          perPage: data.per_page,
          lastPage: data.last_page,
          hasMorePages: data.has_more_pages,
          total: data.total,
          infiniteLoading: this.infinite,
        };

        data = data.data;
      }

      if (this.resolveData) {
        try {
          data = this.resolveData(data);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    }

    return Object.freeze(data) || [];
  }

  private async loadMore(stateChanger: StateChanger, pagination: DataTablePagination) {
    this.stateChanger = stateChanger;

    this.variablesQuery.page = pagination.currentPage + 1;

    if (this.queryResult) {
      const result = await this.queryResult.fetchMore({
        variables: this.variablesQuery,
        updateQuery: (prev, {fetchMoreResult}) => {
          const paginate = fetchMoreResult[this.dataKey];
          paginate.data = Object.freeze([...prev[this.dataKey].data, ...paginate.data]);

          return {
            [this.dataKey]: paginate,
          };
        },
      });

      const data = result.data[this.dataKey];
      const paginator = ('paginatorInfo' in data) ? data.paginatorInfo : data;
      const hasMorePages = ('hasMorePages' in paginator) ? paginator.hasMorePages : paginator.has_more_pages;

      if (!hasMorePages) {
        stateChanger.complete();
      } else {
        stateChanger.loaded();
      }
    }
  }
}
