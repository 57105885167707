




























import { Component, Mixins, Prop } from 'vue-property-decorator';
import ActionModelMixin from '@/layouts/back-office/elements/actions/ActionModelMixin';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';

@Component({
  components: {SpinnerButton, Modal},
})
export default class ConfirmActionModal extends Mixins(ActionModelMixin) {
  @Prop({ type: String, default: 'btn-outline-success' }) private buttonClass!: string;
}
