var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-dealer-accounts"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('query-data-table',{ref:"paginateDealerAccounts",attrs:{"data-key":"paginateDealerAccounts","title":_vm.$it('accounts.list.title', 'Accounts'),"query":require('@/graphql/PaginateDealerAccounts.gql'),"variables":{limit: 50},"row-height":71,"columns":_vm.columns,"searchableFields":_vm.searchable},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var register = ref.register;
var selected = ref.selected;
return [_c('send-password-create-mail-action',{attrs:{"register":register,"selected":selected}})]}},{key:"name",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"row-title"},[_c('router-link',{attrs:{"to":{ name: 'dealer-snapshots', query: { 'paginate-dealer-inspections-filter-inspectorUid': item.id }}}},[_vm._v(_vm._s(value))])],1)]}},{key:"email",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(value))])]}},{key:"hasPassword",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?_c('i',{staticClass:"fa fa-check-circle"}):_c('i',{staticClass:"fa fa-times-circle"})]}},{key:"inspectionsAmountTotal",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_vm._v(" "+_vm._s(item.inspectionsAmountOpen)+" / "+_vm._s(item.inspectionsAmountTotal)+" "),(item.inspectionsAmountOpen === 0)?[_vm._v(" (0%) ")]:[_vm._v(" ("+_vm._s(_vm.$n(item.inspectionsAmountOpen / item.inspectionsAmountTotal * 100, 'percentage'))+") ")]]}},{key:"appVersion",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"app-version",attrs:{"aria-label":_vm.getAppVersionMessage(item),"data-balloon-pos":"left"}},[(item.device !== null)?[(item.device.systemOs === 'Android')?_c('i',{staticClass:"fab fa-android"}):(item.device.systemOs === 'iOS')?_c('i',{staticClass:"fab fa-apple"}):_vm._e()]:_vm._e(),(item.device === null)?_c('i',{staticClass:"fa fa-minus-circle"}):(item.device && item.device.outdated)?_c('i',{staticClass:"fas fa-clock"}):_vm._e(),_c('label',[_vm._v(_vm._s(value || '—'))])],2)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }