

















import {Component, Mixins, Vue} from 'vue-property-decorator';
import ConfirmActionModal from '@/layouts/back-office/elements/actions/ConfirmActionModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';

@Component({
  components: {ConfirmActionModal},
})
export default class SendPasswordCreateMailAction extends Mixins(ActionMixin) {
  public icon: string = 'key';
  public title: string =
    this.$it('snapshot.action.send_dealer_password_reset_create_mail.title', 'Send password mail') as string;
  public permission: string = 'dealers-accounts-send-password-reset';

  get variables() {
    return {
      ids: this.selected.map((item: any) => item.id),
    };
  }

  protected onSelect() {
    const modal: ConfirmActionModal = this.$refs.actionModal as ConfirmActionModal;

    modal.open();
  }
}
